import { black } from '@/constants/Colors';
import { tituloH1 } from '@/constants/Dimens';
import { Typography } from '@mui/material';


//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface TituloH1Props {
    texto: string;
    color?: string;
}

function TituloPrincipalH1({ texto, color }: TituloH1Props) {

    //Tamaño de texto a 25 px
    //Color de texto a primario

    const style: React.CSSProperties = {
        fontSize: tituloH1,
        color: color ? color : black,
        fontWeight: '300',
        userSelect: 'none',
    };

    
    return (
        <Typography component="h1" variant="h5" sx={{ mt: 1 }} style={style}>
            {texto}
        </Typography>
    );

}

export default TituloPrincipalH1;
