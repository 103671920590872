import Grid from '@mui/material/Grid2';
import GoogleAds, { AdSenseFormat } from '../widgets/Adsense/GoogleAds';
import { IDAdsense, SlotAnuncioDisplay } from '@/util/BaseURL';

interface MyGridProps {
    leftComponent: React.ReactNode;
    centerComponent: React.ReactNode;
    rightComponent: React.ReactNode;
    id?: string;
    anuncios?: boolean;
}

const ContenidoPrincipal: React.FC<MyGridProps> = ({ leftComponent, centerComponent, rightComponent, id, anuncios = true }) => {
    return (
        <Grid id={id} container spacing={2} >
            <Grid size={{ xs: 12, sm: 12, md: 2 }} >
                {/*leftComponent*/}
                {anuncios &&
                    <div style={{ width: "100%", textAlign: 'center' }}>
                        <GoogleAds client={IDAdsense} slot={SlotAnuncioDisplay} format={AdSenseFormat.AUTO} dataFullWidthResponsive={true} />
                    </div>
                }
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 8 }} container direction="column">
                <div style={{ width: '100%' }}>
                    {centerComponent}
                </div>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 2 }}>
                {/*rightComponent*/}
                {anuncios &&
                    <div style={{ width: "100%", textAlign: 'center' }}>
                        <GoogleAds client={IDAdsense} slot={SlotAnuncioDisplay} format={AdSenseFormat.AUTO} dataFullWidthResponsive={true} />
                    </div>
                }
            </Grid>
        </Grid>
    );
};

export default ContenidoPrincipal;