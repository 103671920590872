import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaSiteKey } from '@/util/BaseURL';

interface Props {
    captchaRef: any;
    onChange?: (token: string | null) => void;
}

function CaptchaRecaptcha({ captchaRef, onChange }: Props) {
    return (
        <ReCAPTCHA
            sitekey={GoogleReCaptchaSiteKey}
            ref={captchaRef}
            size="invisible"
            onChange={onChange}
        />
    );
}
/*           size="invisible"*/
/* 
           */

export default CaptchaRecaptcha;