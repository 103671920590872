export function decodificarToken(token: string): any {
  // Importamos la biblioteca `jsonwebtoken` para decodificar el token
  const jwt = require("jsonwebtoken");

  // Decodificamos el token y obtenemos la información del usuario
  const decodedToken = jwt.decode(token, { json: true }) as any;

  // Devolvemos la información del usuario
  return decodedToken;
}

export function esMovil(): boolean {
  // Comprobamos si el dispositivo es un móvil
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function resolucionMovilDashboard(): boolean {
  // Comprobamos si la resolución es de un móvil
  return window.innerWidth <= 599;
}