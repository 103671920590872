// src/app/page.tsx
import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { HeadObj } from '@/objects/Page';
import { useServices } from '@/context/ServicesProvider';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import TituloPrincipalH1 from '@/components/elements/TituloPrincipalH1';
import { pathToSection, traduccionesPorPathHead } from '@/util/internacionalizacion/TraduccionesPorPath';
import ContenidoPrincipal from '@/components/elements/ContenidoPrincipal';
import { Card } from '@mui/material';
import Boton from '@/components/elements/Boton';
import { DataTokenGoogle, LoginDataResponse, LoginDatos, LoginUserGoogle, loginUser } from '@/objects/User';
import { cargarDatosUsuario, guardarDatosUsuarioLogin, removeAllLocalStorage } from '@/util/LocalStorageLoaded';
import { mostrarAlerta } from '@/context/UtilsContext';
import TextFieldCustom, { AutocompleteValues, InputTypes } from '@/components/elements/TextFieldCustom';
import { Key, PersonRounded, Login, VisibilityOff } from '@mui/icons-material';
import Texto from '@/components/elements/Texto';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { primary, verde, verdeOscurecido, verdeOscuro, white } from '@/constants/Colors';
import LoginGoogle, { } from '@/components/elements/LoginGoogle';
import { CredentialResponse } from '@react-oauth/google';
import { decodificarToken } from '@/util/Util';
import { GoogleSigninClientId, urlWeb } from '@/util/BaseURL';
import CaptchaRecaptcha from '@/components/elements/CaptchaRecaptcha';
import StoreIcon from '@mui/icons-material/Store';
import BotonHeader from '@/components/elements/BotonHeader';
import RowNoData from '@/components/elements/RowNoData';
import { idiomasDisponibles } from '@/util/Comun';

interface HomeProps {
  pathActual: string;
  mensajeCode?: string;
}
const LoginScreen: React.FC<HomeProps> = ({ pathActual, mensajeCode }) => {

  const { t } = useTranslation();

  const { usersQueries } = useServices();

  const [emailUsuario, setEmailUsuario] = useState("");
  const [passUsuario, setPassUsuario] = useState("");

  const [mensajeTexto, setMensajeTexto] = useState("");

  useEffect(() => {
    if (cargarDatosUsuario() != null) {
      window.location.href = "/"; //Si ya está logeado, que no pueda volver a esta página
      return;
    }
  }, []);

  useEffect(() => {
    if (mensajeCode != null) {
      switch (mensajeCode) {
        case "1": //Verificacion OK
          setMensajeTexto(t('contenido:login.codigo_verificacion_ok'));
          break;
        case "-1": //verificacion error
          setMensajeTexto(t('contenido:login.error_codigo_verificacion_no_valido'));
          break;
        case "2": //Cambio contra ok
          setMensajeTexto(t('contenido:login.codigo_verificacion_ok'));
          break;
      }
    }
  }, [mensajeCode]);


  //Captcha
  const captchaRef = useRef<any>(null);

  async function iniciarSesionGoogle(responseGoogle: CredentialResponse) {
    //const datosUserGoogle = await obtenerDatosUsuarioGoogle(String(responseGoogle.credential));
    const datosGoogle: DataTokenGoogle = decodificarToken(String(responseGoogle.credential));
    //alert(JSON.stringify(datosGoogle));
    const objGoogleIniciarSesion: LoginUserGoogle = {
      "a": datosGoogle.name,
      "b": datosGoogle.email,
      "c": datosGoogle.sub,
      "d": datosGoogle.picture,
      "tokenGoogle": String(responseGoogle.credential),
      "type_app": 1,
      "tipo_android_app": GoogleSigninClientId
    };

    usersQueries?.iniciarSesionGoogle(objGoogleIniciarSesion).then((response: AxiosResponse<LoginDataResponse>) => {
      const loginDataResponse: LoginDataResponse = response.data;

      mostrarAlerta({
        title: t('contenido:login.debes_aceptar_terminos'),
        content: <iframe
          src={"/terminos-y-condiciones?type_app=1"}
          style={{ width: '100%', height: '500px' }}
        />,
        onAccept: () => {
          comprobarIniciarSesion(loginDataResponse);
        },
        acceptText: t('common:opciones.aceptar'),
        showCancelButton: true,
      });

      //alert(JSON.stringify(loginDataResponse));

    }).catch((error) => {
      console.error('Error al iniciar sesión:', error);
    });
  }

  const iniciarSesion = async () => {
    let tokenReCaptha = null;
    const tipeAPP = 2;
    if (tipeAPP == 2) {
      tokenReCaptha = await captchaRef.current.executeAsync();
      captchaRef.current.reset();
    }
    //Quiero que si no se ha cargado el captcha no se ejecute

    //alert('Token: ' + tokenReCaptha);
    if (emailUsuario == "") {
      mostrarAlerta({
        title: t('common:opciones.error'),
        content: t("contenido:login.indique_la_direccion_de_correo"),
        showCancelButton: false,
      });
      return;
    }

    if (passUsuario == "") {
      mostrarAlerta({
        title: t('common:opciones.error'),
        content: t("contenido:login.indique_la_contrasena"),
        showCancelButton: false,
      });
      return;
    }

    const objIniciarSesion: loginUser = {
      usuario: emailUsuario,
      pass: passUsuario,
      typeLogear: true,
      typeRecordar: 0,
      typeAPP: tipeAPP,
      tipo_android_app: "1",
      recaptcha_token: tokenReCaptha != null ? tokenReCaptha : ""
    };

    usersQueries?.iniciarSesion(objIniciarSesion).then((response: AxiosResponse<LoginDataResponse | string>) => {
      const loginDataResponse: LoginDataResponse | string = response.data;
      //Si devuelve -8 es error de TOKEN CAPTCHA
      if (loginDataResponse == "-8") {
        mostrarAlerta({
          title: t('common:opciones.error'),
          content: t("contenido:login.error_captcha"),
          showCancelButton: false,
        });
        return;
      }
      comprobarIniciarSesion(loginDataResponse as LoginDataResponse);
      //alert(JSON.stringify(loginDataResponse));

    }).catch((error) => {
      console.error('Error al iniciar sesión:', error);
      //Si el error es: The quota has been exceeded. borrra el localstorage
      if (String(error).includes("The quota has been exceeded.")) {
        removeAllLocalStorage();
      }

    });
  }

  //https://www.quierolibros.com/login?code=-1


  function comprobarIniciarSesion(loginDataResponse: LoginDataResponse) {
    if (loginDataResponse != null && loginDataResponse != undefined && loginDataResponse.login.length > 0) {
      //alert(JSON.stringify(usuarioDatos));
      var datosLogin = (loginDataResponse.login[0] as LoginDatos);

      if (datosLogin.id_user != null
        && datosLogin.id_user != -1
        && datosLogin.id_user != -2
        && datosLogin.id_user != -3) {

        if (datosLogin.confirm == -1 || datosLogin.confirm == 1) {
          datosLogin.cuentaExterna = false;
          guardarDatosUsuarioLogin(loginDataResponse);
          //Ir a la pantalla principal
          window.location.href = "/";

        } else {
          mostrarAlerta({
            title: t('common:opciones.error'),
            content: t("contenido:login.error_verificar_cuenta"),
            showCancelButton: false,
          });
        }
      } else if (datosLogin.id_user == -1) {
        mostrarAlerta({
          title: t('common:opciones.error'),
          content: t("contenido:login.error_datos_incorrectos"),
          showCancelButton: false,
        });
      } else if (datosLogin.id_user == -2) {

        mostrarAlerta({
          title: t('common:opciones.error'),
          content: t("contenido:login.error_verificar_cuenta"),
          showCancelButton: false,
        });
      } else if (datosLogin.id_user == -3) {
        mostrarAlerta({
          title: t('common:opciones.error'),
          content: t("contenido:login.error_acceso_no_permitido"),
          showCancelButton: false,
        });
      } else {

        mostrarAlerta({
          title: t('common:opciones.error'),
          content: t("contenido:login.error_datos_incorrectos"),
          showCancelButton: false,
        }
        );
      }

    } else {
      mostrarAlerta({
        title: t('common:opciones.error'),
        content: t("contenido:login.error_datos_incorrectos"),
        showCancelButton: false,
      });
    }
  }

  const styleCard: React.CSSProperties = {
    padding: "20px",
    height: "100%",
    width: "100%",
  }

  return (
    <div id="blur_background">
      <ContenidoPrincipal
        anuncios={false}
        leftComponent={<div>Anuncio</div>}
        centerComponent={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", paddingBottom: "20px" }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "400px", }}>
              {mensajeTexto != "" &&
                <RowNoData texto={mensajeTexto} />
              }
              <Card style={styleCard}>
                <TituloPrincipalH1 texto={t(`contenido:${pathActual}.titulo`)} />
                <form noValidate>
                  <TextFieldCustom focus={true} id="email" label={t('contenido:login.email')} value={emailUsuario} setValue={setEmailUsuario}
                    required={false} autocomplete={AutocompleteValues.Email} inputType={InputTypes.Email}
                    leftIcon={<PersonRounded />} />
                  <TextFieldCustom id="password" label={t('contenido:login.password')} value={passUsuario} setValue={setPassUsuario}
                    required={false} autocomplete={AutocompleteValues.CurrentPassword} inputType={InputTypes.Password}
                    leftIcon={<Key />} rightIcon={<VisibilityOff />} passwordShow={true} />
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                    <BotonHeader color={primary} height="30px" texto={t("contenido:login.has_olvidado_password")} link={"/cuenta-olvidada"} title={t("contenido:login.title_abrir_olvidado")} />
                  </div>
                  <CaptchaRecaptcha captchaRef={captchaRef} />
                  <Boton startIcon={<Login />} fullWidth texto={t("contenido:login.iniciar_sesion")} onClick={iniciarSesion} />
                  <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Texto>{t("contenido:login.inicia_con_google")}</Texto>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                      <LoginGoogle fullwidth onSuccess={iniciarSesionGoogle}
                        errorMessage={() => {
                          alert('Google Login Error:');
                        }
                        } />
                    </div>
                  </div>
                  <div>
                    <Texto>{t("contenido:login.no_tienes_cuenta")}</Texto>
                    <Boton color={white} colorTexto={primary} startIcon={<AssignmentIndIcon />} title={t("contenido:login.title_registro")} link={"/registro"} fullWidth texto={t("contenido:login.registrate_gratis_para_comprar_o_vender_libros")} />
                  </div>
                  <div>
                    <Texto>{t("contenido:login.registro_libreria")}</Texto>
                    <Boton color={white} colorTexto={verdeOscurecido} startIcon={<StoreIcon />} title={t("contenido:login.title_registro_libreria")} link={"/registro-tienda"} fullWidth texto={t("contenido:login.registrate_como_libreria")} />
                  </div>
                </form>
              </Card>
            </div>
          </div>

        }
        rightComponent={<div>Anuncio 2</div>}
      />
    </div>
  );
};

export default LoginScreen;


//Generacion de articulos en el servidor para SEO
export async function getServerSideProps(context: any) {
  //Traducciones
  let { locale } = context;

  if (!locale) {
    locale = 'es'; // Reemplaza 'es' con tu localización predeterminada
  }

  const { code } = context.query;

  const mensajeCode = typeof code === 'string' ? code : null;

  const pathActual = pathToSection(context.resolvedUrl);
  //console.log('Path actual lo:' + pathActual);
  //console.log('Idioma Cookie:' + getCookiePais(context));
  const headerObj: HeadObj = await traduccionesPorPathHead(context, locale);

  if (locale === 'es') {
    headerObj.linkCanonical = `${urlWeb}/login`;
  } else {
    headerObj.linkCanonical = `${urlWeb}/${locale}/login`;
  }

  headerObj.alternates = idiomasDisponibles.map((loc) => {
    if (loc === 'es') {
      return { hreflang: loc, href: `${urlWeb}/login` };
    } else {
      return { hreflang: loc, href: `${urlWeb}/${loc}/login` };
    }
  });

  //console.log("ServerSideTranslation " + JSON.stringify(await serverSideTranslations(locale, ['common', "contenido"])));
  return {
    props: {
      headerObj: headerObj, // Pasar el objeto de encabezado
      pathActual: pathActual, //Se le pasa para seleccionar las traducciones propias de esta página (dentro de contenido)
      mensajeCode: mensajeCode,
      ...(await serverSideTranslations(locale, ['common', "contenido", "header"])),
    },
    //revalidate: 60, // Regenera la página cada 60 segundos
  };
}