import React, { useEffect } from 'react';

declare global {
    interface Window {
        adsbygoogle: any;
    }
}

export enum DataAdLayout {
    IN_ARTICLE = 'in-article',
    IN_FEED = 'in-feed'
}

export enum AdSenseFormat {
    AUTO = 'auto',
    RECTANGLE = 'rectangle',
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
    LEADERBOARD = 'leaderboard',
    SKYSCRAPER = 'skyscraper',
    LARGE_RECTANGLE = 'large_rectangle',
    RESPONSIVE = 'responsive',
    FLUID = 'fluid',
    LINK_UNIT = 'link_unit',
    AUTO_RELAXED = 'auto_relaxed',
}

interface AdSenseProps {
    client: string; // ID de cliente de AdSense
    slot: string; // ID de slot de AdSense
    dataAdLayout?: DataAdLayout;
    format: AdSenseFormat; // Formato de anuncio de AdSense
    dataFullWidthResponsive: boolean; // Formato de anuncio de AdSense
}

const GoogleAds: React.FC<AdSenseProps> = ({ client, slot, format, dataFullWidthResponsive, dataAdLayout }) => {
    useEffect(() => {
        // Cargar el script de AdSense solo una vez
       /* const script = document.createElement('script');
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js`;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Eliminar el script cuando el componente se desmonte
            document.body.removeChild(script);
        };*/
    }, []);

    useEffect(() => {
        // Mostrar el anuncio de AdSense
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client={client}
            data-ad-layout={dataAdLayout}
            data-ad-slot={slot}
            data-ad-format={format}
            data-full-width-responsive={dataFullWidthResponsive}
        />
    );
};

export default GoogleAds;
