import Button from '@mui/material/Button';
import { white } from '../../constants/Colors';
import { textoBotones } from '../../constants/Dimens';
import { Box } from '@mui/material';
import Link from 'next/link';  // Asegúrate de importar Link desde next/link

//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface BotonDefectoProps {
    id?: string;
    texto: string;
    link?: string;
    title?: string;
    color?: string; // El color es opcional
    onClick?: () => void; // La función es opcional
    width?: string;
    height?: string;
    endIcon?: JSX.Element | null;
    startIcon?: JSX.Element | null;
}

function BotonHeader({ id = "", texto, link = "", color = white, onClick = () => { }, width = "auto", height = "50px",
    endIcon = null, startIcon = null, title }: BotonDefectoProps) {

    //Style:
    const buttonStyle: React.CSSProperties = {
        color: color,
        fontWeight: 'bold',
        width: width,
        height: height,
        fontSize: textoBotones,
        textTransform: 'none',
        marginTop: '5px',
        marginBottom: '5px',
    };

    const botonLink: React.CSSProperties = {
        width: width,
    };

    return (
        link !== "" ? (
            <Link href={link} title={title} passHref>
                <Button
                    variant="text"
                    style={buttonStyle}
                    onClick={onClick}
                    startIcon={startIcon}
                    endIcon={endIcon}>
                    <Box width="100%">
                        {texto}
                    </Box>
                </Button>
            </Link >
        ) : (
            <Button
                variant="text"
                style={buttonStyle}
                onClick={onClick}
                startIcon={startIcon}
                endIcon={endIcon}
                title={title}
            >
                <Box width="100%">
                    {texto}
                </Box>
            </Button>
        )
    );
}

export default BotonHeader;
