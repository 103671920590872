import React from 'react';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';


export async function obtenerDatosUsuarioGoogle(accessToken: string): Promise<any> {
    const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
    const objUser = await response.json();
    return objUser;
}
interface Props {
    onSuccess: (response: CredentialResponse) => void;
    errorMessage: () => void;
    fullwidth?: boolean;
}

const LoginGoogle: React.FC<Props> = ({ onSuccess, errorMessage, fullwidth }) => {

    const style: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: fullwidth ? '100%' : 'auto',
    }

    return (
        <div style={style}>
            <GoogleLogin onSuccess={onSuccess} onError={errorMessage} />
        </div>
    )
};

export default LoginGoogle;
